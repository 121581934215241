import React from 'react'

export const Ecomix150 = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1314"
    height="775"
    viewBox="0 0 1314 775"
    {...props}
  >
    <defs>
      <pattern
        id="pattern"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 212 188"
      >
        <image
          width="212"
          height="188"
          href="../ecomix-150/PA01.141.13E.3211_2.png"
        />
      </pattern>
      <pattern
        id="pattern-2"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 159 183"
      >
        <image
          width="159"
          height="183"
          href="../ecomix-150/PA01.141.13E.3211_1.png"
        />
      </pattern>
      <pattern
        id="pattern-3"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 44 29"
      >
        <image
          width="44"
          height="29"
          href="../ecomix-150/PA01.141.13E.3211_8.png"
        />
      </pattern>
      <pattern
        id="pattern-4"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 17 19"
      >
        <image
          width="17"
          height="19"
          href="../ecomix-150/PA01.141.13E.3211_4.png"
        />
      </pattern>
      <pattern
        id="pattern-5"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 38 28"
      >
        <image
          width="38"
          height="28"
          href="../ecomix-150/PA01.141.13E.3211_6.png"
        />
      </pattern>
      <pattern
        id="pattern-6"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 18 22"
      >
        <image
          width="18"
          height="22"
          href="../ecomix-150/PA01.141.13E.3211_7.png"
        />
      </pattern>
      <pattern
        id="pattern-7"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 40 72"
      >
        <image
          width="40"
          height="72"
          href="../ecomix-150/PA01.141.13E.3211_16.png"
        />
      </pattern>
      <pattern
        id="pattern-8"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 114 145"
      >
        <image
          width="114"
          height="145"
          href="../ecomix-150/PA01.141.13E.3211_15.png"
        />
      </pattern>
      <pattern
        id="pattern-9"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 56 48"
      >
        <image
          width="56"
          height="48"
          href="../ecomix-150/PA01.141.13E.3211_14.png"
        />
      </pattern>
      <pattern
        id="pattern-10"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 37 25"
      >
        <image
          width="37"
          height="25"
          href="../ecomix-150/PA01.141.13E.3211_12.png"
        />
      </pattern>
      <pattern
        id="pattern-11"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 34 23"
      >
        <image
          width="34"
          height="23"
          href="../ecomix-150/PA01.141.13E.3211_9.png"
        />
      </pattern>
      <pattern
        id="pattern-12"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 257 263"
      >
        <image
          width="257"
          height="263"
          href="../ecomix-150/PA01.141.13E.3211_13.png"
        />
      </pattern>
      <pattern
        id="pattern-13"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 197 230"
      >
        <image
          width="197"
          height="230"
          href="../ecomix-150/PA01.141.13E.3211_18.png"
        />
      </pattern>
      <pattern
        id="pattern-14"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 125 145"
      >
        <image
          width="125"
          height="145"
          href="../ecomix-150/PA01.141.13E.3211_19.png"
        />
      </pattern>
    </defs>
    <g
      className="vista-g"
      id="Group_83"
      data-name="Group 83"
      transform="translate(-354 -172)"
    >
      <rect
        id="PA01.141.13E.3211_2"
        width="304"
        height="270"
        transform="translate(590 237)"
        fill="url(#pattern)"
      />
       <rect
        id="PA01.121.13E.3211_2"
        width="203"
        height="233"
        transform="translate(323 239)"
        fill="url(#pattern-2)"
        data-url="https://shopirbal.com/pt/produto/cremalheira-b1-laranja-125-140/"
        data-name="Cremalheira de Ferro Fundido 130-150-155"
      />
      <image
        id="PA01.141.13E.3211_3"
        width="199"
        height="262"
        transform="translate(513 656)"
        href="../ecomix-150/PA01.141.13E.3211_3.png"
      />
      <image
        id="PA01.141.13E.3211_11"
        width="419"
        height="580"
        transform="translate(823 367)"
        href="../ecomix-150/PA01.141.13E.3211_11.png"
      />
      <rect
        id="PA01.141.13E.3211_8"
        width="63"
        height="42"
        transform="translate(746 535)"
        fill="url(#pattern-3)"
        data-url="https://shopirbal.com/pt/produto/rolamento-rl-6207-2rs/"
        data-name="Rolamento do Cubo do Balde RL 6207 2RS"
      />
      <rect
        id="PA01.141.13E.3211_4"
        width="24"
        height="27"
        transform="translate(713 617)"
        fill="url(#pattern-4)"
      />
      <rect
        id="PA01.141.13E.3211_6"
        width="55"
        height="40"
        transform="translate(918 432)"
        fill="url(#pattern-5)"
        data-url="https://shopirbal.com/pt/produto/pinhao-carreto-b1-150-cavilha/"
        data-name="Pinhão/Carreto B1-150 + Cavilha"
      />
      <rect
        id="PA01.141.13E.3211_7"
        width="26"
        height="32"
        transform="translate(959 305)"
        fill="url(#pattern-6)"
        data-url="https://shopirbal.com/pt/produto/interruptor-b1/"
        data-name="Interruptor B1"
      />
      <rect
        id="PA01.141.13E.3211_16"
        width="57"
        height="102"
        transform="translate(1203 172)"
        fill="url(#pattern-7)"
        data-url="https://shopirbal.com/pt/produto/correia-6pj-636-elastica/"
        data-name="Correia 6PJ 636 Elástica"
      />
      <rect
        id="PA01.141.13E.3211_15"
        width="163"
        height="208"
        transform="translate(1355 203)"
        fill="url(#pattern-8)"
      />
      <rect
        id="PA01.141.13E.3211_14"
        width="81"
        height="69"
        transform="translate(1270 379)"
        fill="url(#pattern-9)"
      />
      <rect
        id="PA01.141.13E.3211_12"
        width="53"
        height="36"
        transform="translate(1284 635)"
        fill="url(#pattern-10)"
      />
      <rect
        id="PA01.141.13E.3211_9"
        width="48"
        height="33"
        transform="translate(1133 327)"
        fill="url(#pattern-11)"
        data-url="https://shopirbal.com/pt/produto/rolamento-6303-2rs/"
        data-name="Rolamento do Lado do Pinhão RL 6303 2RS"
      />
      <image
        id="PA01.141.13E.3211_10"
        width="66"
        height="86"
        transform="translate(1303 798)"
        href="../ecomix-150/PA01.141.13E.3211_10.png"
        data-url="https://shopirbal.com/pt/produto/roda-macica-200-com-freio/"
        data-name="Roda Maciça Ø200 com Freio"
      />
      <rect
        id="PA01.141.13E.3211_13"
        width="107"
        height="109"
        transform="translate(1423 631)"
        fill="url(#pattern-12)"
        data-url="https://shopirbal.com/pt/produto/motor-ele-700w/"
        data-name="Motor ELE 700W"
      />
      <rect
        id="PA01.141.13E.3211_18"
        width="81"
        height="95"
        transform="translate(1587 523)"
        fill="url(#pattern-13)"
        data-url="https://shopirbal.com/pt/produto/ventoinha-para-motor-700w/"
        data-name="Ventoinha para Motor 700W"
      />
      <rect
        id="PA01.141.13E.3211_19"
        width="51"
        height="60"
        transform="translate(1427 510)"
        fill="url(#pattern-14)"
        data-url="https://shopirbal.com/pt/produto/condensador-700wt/"
        data-name="Condensador 700W"
      />
    </g>
  </svg>
)
